import React from "react";
import { Trans } from "@lingui/macro";
import { Link } from "react-router-dom";
import { Routes } from "common/routes";

const FAQButton = () => {
  return (
    <div className="flex items-center my-4 justify-end leading-8">
      <Link
        to={Routes.FAQ_PAGE}
        id="home-faq-btn"
        className="h-10 bg-white px-4 rounded-l-full flex items-center text-web-bluelight font-semibold text-sm"
      >
        <Trans>Home/FAQ</Trans>
      </Link>
    </div>
  );
};

export default FAQButton;
