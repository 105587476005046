import React, { FC, CSSProperties } from "react";

export enum Variant {
  solid = "solid",
  dashed = "dashed",
  dotted = "dotted",
  double = "double",
  none = "none",
}

export enum BorderWidth {
  xs = "-0",
  sm = "",
  md = "-2",
  lg = "-4",
}

interface Props {
  variant?: Variant;
  borderWidth?: BorderWidth;
  className?: string;
}

const Divider: FC<Props> = ({
  className,
  variant = Variant.solid,
  borderWidth = BorderWidth.sm,
}) => {
  return (
    <hr
      className={`border-t${borderWidth} border-${variant} border-white my-4 ${className}`}
    />
  );
};

export default Divider;
