import React, { FC, ChangeEventHandler, CSSProperties } from "react";

import styles from "./style.module.scss";

interface Option {
  label: string;
  value: any;
  isDisabled?: boolean;
}

interface Props {
  name: string;
  value: any;
  options: Option[];
  onChange: ChangeEventHandler;
  containerClassName?: string;
  labelClassName?: string;
  labelStyle?: CSSProperties;
  uppercase?: boolean;
  isOneLine?: boolean;
}

const FieldRadioGroup: FC<Props> = ({
  name,
  value,
  options,
  onChange,
  containerClassName,
  labelClassName,
  uppercase,
  isOneLine,
}) => {
  const renderOptions = () => {
    return options.map((option, index) => {
      const identifier = `${name}_${index}`;
      return (
        <div className={containerClassName} key={identifier}>
          <label
            htmlFor={identifier}
            className={`${
              option.value === value ? "font-semibold" : ""
            } ${labelClassName} shadow-input ${isOneLine ? "whitespace-no-wrap" : ""}`}
            data-testid="radio-label"
          >
            <input
              data-testid="radio-input"
              type="radio"
              id={identifier}
              name={name}
              className={styles.radio}
              value={option.value}
              checked={option.value === value}
              disabled={option.isDisabled}
              onChange={onChange}
              data-checked={option.value === value}
            />
            <span className={`${uppercase ? "uppercase" : ""} text-base`}>
              {option.label}
            </span>
          </label>
        </div>
      );
    });
  };

  return <div className={styles.radioGroup}>{renderOptions()}</div>;
};

export default FieldRadioGroup;
