import React, { FC, ReactNode, CSSProperties } from "react";

interface Props {
  className?: string;
  children: ReactNode;
}

const PageTitle: FC<Props> = ({ children, className }) => {
  return <h1 className={`text-white text-2xl font-bold ${className}`}>{children}</h1>;
};

export default PageTitle;
