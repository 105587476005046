import React, { lazy, Suspense, useEffect } from "react";
import { useHistory, Route, Switch, useLocation } from "react-router-dom";
import Cookies from "js-cookie";
import queryString from "query-string";
import { format } from "date-fns";

import { Routes } from "common/routes";
import { activeLocale, Locale } from "i18n";
import { PageFooter, PageHeader, Banner, AnnouncementBanner } from "components";
import PermitPage from "pages/PermitPage";
import Spinner, { SpinnerColor, SpinnerSize } from "shared-components/Spinner";
import getGlobal from "utils/getGlobal";
import PageRoute from "./PageRoute";
import styles from "./style.module.scss";

const FaqPage = lazy(() => import("pages/FaqPage"));
const InfoPage = lazy(() => import("pages/InfoPage"));
const PersonalInfoPage = lazy(() => import("pages/PersonalInfoPage"));
const ExitDetailsPage = lazy(() => import("pages/ExitDetailsPage"));
const NotFoundPage = lazy(() => import("pages/NotFoundPage"));
const UnavailablePage = lazy(() => import("pages/NotFoundPage/UnavailablePage"));
const StyleGuidePage = lazy(() => import("pages/StyleGuide"));
const TermsOfUsePage = lazy(() => import("pages/TermsOfUsePage"));
const PrivacyStatementPage = lazy(() => import("pages/PrivacyStatementPage"));
const PlannedMaintenancePage = lazy(
  () => import("pages/NotFoundPage/PlannedMaintenancePage")
);
const AdhocMaintenancePage = lazy(
  () => import("pages/NotFoundPage/AdhocMaintenancePage")
);
const RedirectionPage = lazy(() => import("pages/NotFoundPage/RedirectPage"));
interface Window {
  [key: string]: any; // Add index signature
}

export enum ESiteModes {
  LIVE = "LIVE", // Default
  PLANNED_MAINTENANCE = "PLANNED_MAINTENANCE",
  ADHOC_MAINTENANCE = "ADHOC_MAINTENANCE",
  UNAVAILABLE = "UNAVAILABLE",
  REDIRECTED = "REDIRECTED",
}

const RoutesUrl = () => {
  switch (getGlobal("REACT_APP_SITE_MODE")) {
    case ESiteModes.UNAVAILABLE:
      return (
        <Switch>
          <Route component={UnavailablePage} />
        </Switch>
      );
    case ESiteModes.PLANNED_MAINTENANCE:
      return (
        <Switch>
          <PageRoute exact path={Routes.TERMS_OF_USE_PAGE} component={TermsOfUsePage} />
          <PageRoute
            exact
            path={Routes.PRIVACY_STATEMENT_PAGE}
            component={PrivacyStatementPage}
          />
          <Route component={PlannedMaintenancePage} />
        </Switch>
      );
    case ESiteModes.ADHOC_MAINTENANCE:
      return (
        <Switch>
          <PageRoute exact path={Routes.TERMS_OF_USE_PAGE} component={TermsOfUsePage} />
          <PageRoute
            exact
            path={Routes.PRIVACY_STATEMENT_PAGE}
            component={PrivacyStatementPage}
          />
          <Route component={AdhocMaintenancePage} />
        </Switch>
      );
    case ESiteModes.REDIRECTED:
      return (
        <Switch>
          <Route component={RedirectionPage} />
        </Switch>
      );
    default:
      return (
        <Switch>
          <Route exact path={Routes.FAQ_PAGE} component={FaqPage} />
          <PageRoute exact path={Routes.INFO_PAGE} component={InfoPage} />
          <PageRoute
            exact
            path={Routes.PERSONAL_INFO_PAGE}
            component={PersonalInfoPage}
          />
          <PageRoute exact path={Routes.EXIT_DETAILS_PAGE} component={ExitDetailsPage} />
          <PageRoute exact path={Routes.PERMIT_PAGE} component={PermitPage} />
          <PageRoute exact path={Routes.TERMS_OF_USE_PAGE} component={TermsOfUsePage} />
          <PageRoute
            exact
            path={Routes.PRIVACY_STATEMENT_PAGE}
            component={PrivacyStatementPage}
          />
          <Route exact path={Routes.MAINTENANCE_PAGE} component={AdhocMaintenancePage} />
          <Route exact path={Routes.REDIRECTION_PAGE} component={RedirectionPage} />
          {getGlobal("REACT_APP_ENV") !== "prd" && (
            <PageRoute exact path={Routes.STYLE_GUIDE_PAGE} component={StyleGuidePage} />
          )}
          <PageRoute component={NotFoundPage} />
        </Switch>
      );
  }
};

const scrollToQuestion = (id: any) => {
  setTimeout(() => {
    const element = document.getElementById(id);
    if (element) element.scrollIntoView();
  }, 500);
};

const lastUpdatedDate = format(
  getGlobal("REACT_APP_BUILD_TIME")
    ? new Date(parseInt(getGlobal("REACT_APP_BUILD_TIME")) * 1000)
    : new Date(),
  "dd MMM yyyy"
);

const AppRouter = () => {
  const { search, pathname } = useLocation();
  const history = useHistory();

  const hashQuestion = history?.location?.hash || "";
  if (hashQuestion) scrollToQuestion(hashQuestion);

  useEffect(() => {
    const query = queryString.parse(search);
    let lang: Locale = Locale.EN;
    if (!query.lang) {
      lang = (Cookies.get("lang") as Locale) || Locale.EN;
    } else {
      switch (query.lang) {
        case Locale.ZH:
        case Locale.MS:
        case Locale.TA:
        case Locale.EN:
        case Locale.BN:
          lang = query.lang as Locale;
          break;
      }
      Cookies.set("lang", lang);
    }
    activeLocale(lang);
  }, [search]);

  useEffect(() => {
    const scrollToTop = () => {
      try {
        window.scroll({
          top: 0,
          left: 0,
          behavior: "smooth",
        });
      } catch (error) {
        // just a fallback for older browsers
        window.scrollTo(0, 0);
      }
    };

    if (pathname) {
      scrollToTop();
    }
  }, [pathname]);

  return (
    <Suspense
      fallback={<Spinner color={SpinnerColor.PINK} size={SpinnerSize.LARGE} isCenter />}
    >
      <div className="h-full overflow-auto flex flex-col">
        <AnnouncementBanner />
        <PageHeader />
        <div className={styles.background}>
          {/*
            Temporary remove option to set locale as
            1. site is set to unavailable (keep warm state)
            2. page (UnavailablePage) does not have the text translated

            <Banner />
          */}
          <RoutesUrl />
        </div>
        <PageFooter lastUpdatedDate={lastUpdatedDate} />
      </div>
    </Suspense>
  );
};

export default AppRouter;
