import React, { ReactNode, useState, useRef } from "react";
import { Trans } from "@lingui/macro";
import styles from "./style.module.scss";

interface Props {
  number: number;
  title: string | ReactNode;
  id?: string;
  children: ReactNode;
  isInitialVisible?: boolean;
}

const QuestionCollection = ({
  title,
  id,
  children,
  isInitialVisible = false,
  number,
}: Props) => {
  const [isVisible, setIsVisible] = useState<boolean>(isInitialVisible);
  const titleRef = useRef(null);

  const handleTitleClick = () => {
    setIsVisible(!isVisible);
  };

  const handleCollapseClick = () => {
    setIsVisible(false);
    if (titleRef?.current) {
      // @ts-ignore
      titleRef.current.focus();
    }
  };

  const topIcon = isVisible ? "minus" : "plus";
  const display = isVisible ? "block" : "hidden";

  return (
    <div className="mt-3">
      <p id={`q${number}`} />
      <button
        ref={titleRef}
        onClick={handleTitleClick}
        id={id}
        className={`${styles.title} w-full flex justify-between items-center bg-web-greenlight px-4 sm:px-8 py-5 text-xl focus:outline-none`}
      >
        <span className="text-left">{title}</span>
        <i className={`fa fa-${topIcon}-square ml-2 text-web-pink`} />
      </button>

      <div
        data-testid="question-collection-children"
        className={`${styles.content} bg-white  ${display}`}
      >
        <div className="p-4 sm:p-8">{children}</div>
        <button
          className="block px-8 py-5 ml-auto text-xs font-semibold focus:outline-none"
          onClick={handleCollapseClick}
        >
          <Trans>COLLAPSE</Trans>
          <i className="fa fa-minus-square ml-2 text-web-pink" />
        </button>
      </div>
    </div>
  );
};

export default QuestionCollection;
