import React, { FC, ReactNode, CSSProperties } from "react";

interface Props {
  className?: string;
  children: ReactNode;
}

const FormTitle: FC<Props> = ({ children, className }) => {
  return (
    <h2 className={`text-white text-base font-medium mb-2 ${className}`}>{children}</h2>
  );
};

export default FormTitle;
