import { i18n, Messages } from "@lingui/core";
import { en as pluralsEN, zh as pluralsZH } from "make-plural/plurals";
// @ts-ignore
import { messages as en } from "locales/en/messages.mjs";
// @ts-ignore
import { messages as zh } from "locales/zh/messages.mjs";
// @ts-ignore
import { messages as ta } from "locales/ta/messages.mjs";
// @ts-ignore
import { messages as bn } from "locales/bn/messages.mjs";
// @ts-ignore
import { messages as ms } from "locales/ms/messages.mjs";

export enum Locale {
  ZH = "zh",
  MS = "ms",
  TA = "ta",
  EN = "en",
  BN = "bn",
}

const messagesMap: { [key: string]: Messages } = {
  [Locale.EN]: en,
  [Locale.ZH]: zh,
  [Locale.TA]: ta,
  [Locale.BN]: bn,
  [Locale.MS]: ms,
};

export async function activeLocale(locale: Locale) {
  const messages = messagesMap[locale];
  i18n.load(locale, messages);
  i18n.activate(locale);
}

i18n.loadLocaleData(Locale.EN, { plurals: pluralsEN });
i18n.loadLocaleData(Locale.ZH, { plurals: pluralsZH });
activeLocale(Locale.EN);

export default i18n;
