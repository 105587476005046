import React, { FC } from "react";
import styles from "./style.module.scss";

interface Props {
  message: string | React.ReactNode;
}

const FormError: FC<Props> = ({ message }) => {
  return (
    <div
      className={`${styles.body} px-3 py-2 bg-web-reddark text-white text-sm flex rounded-b`}
    >
      <i className={`${styles.icon} far fa-exclamation-circle mt-1`}></i>
      {message}
    </div>
  );
};

export default FormError;
