import React, { FC } from "react";
import { useLocation, useHistory } from "react-router-dom";
import queryString from "query-string";

import { Locale } from "i18n";
import GridContainer from "shared-components/GridContainer";
import styles from "./style.module.scss";

export const languageTypes = [
  { label: "English", value: Locale.EN },
  { label: "中文", value: Locale.ZH },
  { label: "Melayu", value: Locale.MS },
  { label: "தமிழ்", value: Locale.TA },
  { label: "বাংলা", value: Locale.BN },
];

interface Props {
  id?: string;
}

const Banner: FC<Props> = ({ id }) => {
  const { search, pathname } = useLocation();
  const { push } = useHistory();

  const handleClick = (event: any) => {
    const lang = event.currentTarget.getAttribute("data-lang");
    const query = queryString.parse(search);
    query.lang = lang;

    push({ pathname, search: queryString.stringify(query) });

    // Encountered a bug from @lingui, need to re-push default language
    if (lang === Locale.EN) {
      setTimeout(() => push({ pathname, search: queryString.stringify(query) }), 0);
    }
  };

  const renderLanguageSelector = () => {
    return (
      <section className={`top-0 w-full ${styles.background}`} id="language-selector">
        <GridContainer className={styles.content}>
          <div className="flex items-center justify-center py-2 sm:justify-end">
            {languageTypes.map((language, index) => {
              return (
                <div key={`langBtn_${index}`} className={`${styles.langTitle}`}>
                  <button
                    className="text-white h-8 focus:outline-none whitespace-no-wrap"
                    data-lang={language.value}
                    onClick={handleClick}
                  >
                    {language.label}
                  </button>
                </div>
              );
            })}
          </div>
        </GridContainer>
      </section>
    );
  };

  return (
    <>
      <div id={id} className="bg-no-repeat bg-center bg-cover w-full">
        {renderLanguageSelector()}
      </div>
    </>
  );
};

export default Banner;
