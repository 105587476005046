import React, { FC, ReactNode } from "react";
import { Trans, t } from "@lingui/macro";
import { format, parse } from "date-fns";
import _i18n from "i18n";
import { LocationVisit } from "shared-components";
import exitLogo from "../imgs/exit-pass.png";
import PermitApplicant from "pages/PermitPage/PermitApplicant";
import { PermitDetails } from "pages/PermitPage/permitSlice";
import { hideStr } from "utils/strings";
import styles from "./style.module.scss";

interface ContentGroupProps {
  title: string | ReactNode;
  content: string | ReactNode;
}

interface Props {
  permit: PermitDetails;
  id?: string;
  hasPOPS: boolean;
}

const ContentGroup: FC<ContentGroupProps> = ({ title, content }) => {
  return (
    <section className={styles.contentGroup}>
      <h2 className={styles.contentTitle}>{title}</h2>
      <p>{content}</p>
    </section>
  );
};

interface ApplicantsGroupProps {
  title: string;
  applicant: { identityNumber: string; id: string };
}

const ApplicantsGroup: FC<ApplicantsGroupProps> = ({ title, applicant }) => {
  return (
    <section className={styles.contentGroup}>
      <h2 className={styles.contentTitle}>{title}</h2>
      <div className="mt-2">
        <div className={styles.applicants}>
          <PermitApplicant key={applicant.identityNumber} applicant={applicant} />
        </div>
      </div>
    </section>
  );
};

const Permit: FC<Props> = ({ id, permit, hasPOPS }) => {
  const { identityNumber, id: permitId, validDate, validTimeFrom, validTimeTo } = permit;
  const start = parse(validTimeFrom, "HH:mm", new Date());
  const end = parse(validTimeTo, "HH:mm", new Date());
  const range = `${format(start, "hh:mma")} - ${format(end, "hh:mma")}`;

  const exitReturnTime = (
    <div className="mb-4">
      <ContentGroup title={_i18n._(t`Exit-Return Time`)} content={range} />
    </div>
  );
  return (
    <div className={styles.paper} id={id}>
      <div
        className={`${styles.titleWrapper} p-6 rounded-lg flex justify-between items-center`}
      >
        <h1 className={styles.title}>
          {hasPOPS ? (
            <Trans>Popular Places Pass</Trans>
          ) : (
            <Trans>Dormitory Exit Pass</Trans>
          )}
        </h1>
        <img src={exitLogo} width="25px" />
      </div>
      <div className={styles.content}>
        <div className="sm:flex sm:flex-col">
          <div className="flex flex-col sm:flex-row justify-between">
            <div className="mb-4">
              <ContentGroup
                title={_i18n._(t`Exit Day`)}
                content={
                  <span className="uppercase">
                    {format(
                      parse(validDate, "yyyy-MM-dd", new Date()),
                      "EEE, dd-MM-yyyy"
                    )}
                  </span>
                }
              />
            </div>
            {!hasPOPS && exitReturnTime}
          </div>
          <div className="mb-4">
            <LocationVisit isCard={false} isPermitPage={true} />
          </div>
        </div>
        <ApplicantsGroup
          title={_i18n._(t`Issued to`)}
          applicant={{
            identityNumber: hideStr(identityNumber),
            id: permitId,
          }}
        />
      </div>
    </div>
  );
};

export default Permit;
