import { default as ReactModal } from "react-modal";
import React, { FC, MouseEventHandler, ReactNode } from "react";

import styles from "./style.module.scss";

interface Props {
  isOpen: boolean;
  onRequestClose: MouseEventHandler;
  contentLabel: string;
  children: ReactNode;
}

const ConfirmModal: FC<Props> = ({
  isOpen,
  onRequestClose,
  contentLabel,
  children,
}) => {
  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={onRequestClose}
      contentLabel={contentLabel}
      overlayClassName={styles.overlay}
      className={styles.content}
      bodyOpenClassName="overflow-hidden"
      htmlOpenClassName="overflow-hidden"
      ariaHideApp={false}
    >
      {children}
    </ReactModal>
  );
};

export default ConfirmModal;
