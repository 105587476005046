import React, { FC, ReactNode } from "react";

import GridContainer from "shared-components/GridContainer";

interface Props {
  title: string;
  children?: ReactNode;
}

const CardNotice: FC<Props> = ({ title, children }) => {
  return (
    <div className="bg-white shadow">
      <GridContainer>
        <div className="py-8">
          <div className="px-4 border-web-pink border-l-4 py-2">
            <p className="font-semibold mb-2 uppercase">{title}</p>
            <div className="text-sm">{children}</div>
          </div>
        </div>
      </GridContainer>
    </div>
  );
};

export default CardNotice;
