export enum Routes {
  // internal
  FAQ_PAGE = "/",
  INFO_PAGE = "/apply",
  PERSONAL_INFO_PAGE = "/apply/particulars",
  EXIT_DETAILS_PAGE = "/apply/exit-details",
  PERMIT_PAGE = "/pass-issued",
  STYLE_GUIDE_PAGE = "/style-guide",
  MAINTENANCE_PAGE = "/maintenance",
  REDIRECTION_PAGE = "/redirect",
  TERMS_OF_USE_PAGE = "/terms-of-use",
  PRIVACY_STATEMENT_PAGE = "/privacy-statement",

  // external
  GOV_SG = "https://www.gov.sg/",
  MOM_GOV_SG = "https://www.mom.gov.sg",
  REPORT_VULNERABILITY = "https://www.tech.gov.sg/report_vulnerability",
  FAQ_PAGE_MOM = "https://www.mom.gov.sg/covid-19/frequently-asked-questions",
  FEEDBACK_FORM_MOM = "https://service2.mom.gov.sg/efeedback/?option=18",
  TRUSTED_WEBSITES = "https://www.gov.sg/trusted-sites#govsites",
}
