import { Link, useLocation } from "react-router-dom";
import classnames from "classnames";
import queryString from "query-string";
import React, { FC, ReactNode, MouseEventHandler } from "react";

export enum HyperlinkAppearance {
  BLUEDARK = "bluedark",
  BLUELIGHT = "bluelight",
  BLUE = "blue",
  UNDERLINE = "underline",
  DEFAULT = "default",
}

interface Props {
  path: string;
  children: ReactNode;
  isInternal: boolean;
  appearance: HyperlinkAppearance;
  onClick?: MouseEventHandler;
  className?: string;
  openInNewTab?: boolean;
}

const Hyperlink: FC<Props> = ({
  path,
  children,
  isInternal,
  appearance,
  className,
  onClick,
  openInNewTab = true,
}) => {
  const { search } = useLocation();
  const query = queryString.parse(search);

  const appearanceClass = {
    "text-web-bluedark underline": appearance === HyperlinkAppearance.BLUEDARK,
    "text-web-blue underline": appearance === HyperlinkAppearance.BLUE,
    "text-web-bluelight underline": appearance === HyperlinkAppearance.BLUELIGHT,
    underline: appearance === HyperlinkAppearance.UNDERLINE,
  };

  return isInternal ? (
    <Link
      data-testid="hyperlink-internal"
      to={queryString.stringifyUrl({ url: path, query: { lang: query.lang } })}
      className={classnames(appearanceClass, className)}
      target={`${openInNewTab ? "_blank" : "_self"}`}
      onClick={onClick}
    >
      {children}
    </Link>
  ) : (
    <a
      data-testid="hyperlink-external"
      href={path}
      rel="noopener noreferrer"
      target={`${openInNewTab ? "_blank" : "_self"}`}
      className={classnames(appearanceClass, className)}
    >
      {children}
    </a>
  );
};

export default Hyperlink;
