import React, { FC, useState, useEffect } from "react";
import _i18n from "i18n";
import { useStateWithSessionStorage } from "utils/customHooks";
import { getBannerContent, BannerContentType } from "./helper";
import "./styles.scss";

export const SESSION_KEY = "AnnouncementBannerDismiss";

const AnnouncementBanner: FC = () => {
  const [isDismiss, setDismiss] = useStateWithSessionStorage(SESSION_KEY);
  const [bannerContent, setBannerContent] = useState<BannerContentType>(null);

  useEffect(() => {
    (async () => {
      const content = await getBannerContent();
      setBannerContent(content);
    })();
  }, [_i18n._locale]); // eslint-disable-line

  if (isDismiss || !bannerContent?.shouldDisplay) return null;

  const { messages, timeString } = bannerContent;

  return (
    <div
      data-testid="bannerTestId"
      className="px-4 sm:px-8 py-4 bg-black text-white text-center sticky top-0 z-50 text-base leading-5"
    >
      <div
        data-testid="dismissBtn"
        className="fa fa-times absolute dismiss-btn"
        onClick={() => setDismiss("true")}
      />
      <div className="max-w-6xl mx-auto" dangerouslySetInnerHTML={{ __html: messages }} />
      {timeString && <div className="font-semibold">{timeString}</div>}
    </div>
  );
};

export default AnnouncementBanner;
