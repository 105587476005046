import React, { FC } from "react";
import { Trans } from "@lingui/macro";
import styles from "./style.module.scss";

interface PermitApplicantProps {
  applicant: { identityNumber: string; id: string };
}

const PermitApplicant: FC<PermitApplicantProps> = ({ applicant }) => {
  return (
    <div className={`${styles.body} flex rounded-lg items-center`}>
      <div className="mb-3">
        <h4 className="text-base font-semibold">
          <Trans>FIN</Trans>
        </h4>
        <p>{applicant.identityNumber}</p>
      </div>
      <div>
        <h4 className="text-base font-semibold">
          <Trans>Reference No.</Trans>
        </h4>
        <p className="break-all">{applicant.id}</p>
      </div>
    </div>
  );
};

export default PermitApplicant;
