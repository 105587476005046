import { Helmet } from "react-helmet";
import React, { FC } from "react";

export enum Meta {
  SITE_NAME = "Apply for a Pass",
  SITE_DESCRIPTION = "You can apply for Pass.",
  PAGE_FAQ = "Frequently Asked Questions",
}

interface Props {
  page: Meta;
}

const MetaHeaders: FC<Props> = () => {
  return (
    <Helmet>
      <title>{Meta.SITE_NAME}</title>
      <meta property="og:title" content={`${Meta.SITE_NAME}`} />
      <meta name="description" content={Meta.SITE_DESCRIPTION} />
      <meta property="og:description" content={Meta.SITE_DESCRIPTION} />
    </Helmet>
  );
};

export default MetaHeaders;
