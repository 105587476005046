import React, { FC } from "react";
import { Trans } from "@lingui/macro";
import { Divider, PageContent, PageTitle } from "shared-components";
import getGlobal from "utils/getGlobal";
import _i18n from "i18n";
import { CycleType, Quota } from "pages/PersonalInfoPage/applicationDetailSlice";
import { popularPlacesLocations } from "utils/constants";
import MainHeaderTitle from "shared-components/MainHeaderTitle";

const Bold: FC = ({ children }) => <span className="font-semibold">{children}</span>;

type Props = {
  hasNonRC?: boolean;
  hasRC?: boolean;
  quota?: Quota;
};

//Display for RC for all w valid healthcode and able to apply ExitPass
const vaccinatedRCMessage = (quota: Quota) => {
  const cycleType = quota.rc?.cycleType.toLowerCase(); // for lowercase display of day/month
  const cycleCount = quota.rc?.cycleCount;
  const cycleQuota = quota.rc?.cycleQuota;
  const daysInAdvance = quota.daysInAdvance;

  let period = cycleType;
  if (cycleCount != undefined && cycleCount > 1) period = `${cycleCount} ${cycleType}s`;

  const rcAllowanceMessage = _i18n._(
    `You may apply for ${cycleQuota} pass every ${period}, up to ${daysInAdvance} days in advance.`
  );

  return (
    <>
      <PageContent className="mt-6 font-normal flex">
        <Trans>
          <Bold>For Recreation Centre (RC)</Bold>
        </Trans>
      </PageContent>

      <PageContent className="mt-2 font-normal flex">
        <span className="ml-2 mr-4">•</span>
        {rcAllowanceMessage}
      </PageContent>
      <PageContent className="mt-2 font-normal flex">
        <span className="ml-2 mr-4">•</span>
        <Trans>
          On the day of the visit, you must show your valid exit pass to your dorm
          operator before you can exit the dormitory.
        </Trans>
      </PageContent>
    </>
  );
};

//Display for NonRC (Vaccination controls removed due to policy)
const nonRCMessage = (quota: Quota) => {
  const cycleType = quota["non-rc"]?.cycleType.toLowerCase(); // for lowercase display of day/month
  const cycleCount = quota["non-rc"]?.cycleCount;
  const cycleQuota = quota["non-rc"]?.cycleQuota;
  const daysInAdvance = quota.daysInAdvance;

  let period = cycleType;
  if (cycleCount != undefined && cycleCount > 1) period = `${cycleCount} ${cycleType}s`;

  const nonRCAllowanceMessage = _i18n._(
    `You may apply for ${cycleQuota} pass every ${period}, up to ${daysInAdvance} days in advance.`
  );
  return (
    <>
      <PageContent className="mt-6 font-normal flex">
        <Trans>
          <Bold>For Non-RC areas</Bold>
        </Trans>
      </PageContent>
      <PageContent className="mt-2 font-normal flex">
        <span className="ml-2 mr-4">•</span>
        {nonRCAllowanceMessage}
      </PageContent>
      <PageContent className="mt-2 font-normal flex">
        <span className="ml-2 mr-4">•</span>
        <Trans>
          On the day of the visit, you must show your valid exit pass to your dorm
          operator before you can exit the dormitory.
        </Trans>
      </PageContent>
      <PageContent className="mt-2 font-normal flex">
        <span className="ml-2 mr-4">•</span>
        <Trans>You may choose to use your Non-RC exit pass to visit the RC</Trans>
      </PageContent>
    </>
  );
};

//Display for PopularPlaces
const popularPlacesMessage = (quota: Quota) => {
  const cycleType = quota["popular-place"]?.cycleType.toLowerCase(); // for lowercase display of day/month
  const cycleCount = quota["popular-place"]?.cycleCount;
  const cycleQuota = quota["popular-place"]?.cycleQuota;
  const daysInAdvance = quota.daysInAdvance;

  let period = cycleType;
  if (cycleCount != undefined && cycleCount > 1) period = `${cycleCount} ${cycleType}s`;

  const popularPlacesAllowanceMessage = _i18n._(
    `You may apply for ${cycleQuota} pass every ${period}, up to ${daysInAdvance} days in advance.`
  );
  return (
    <>
      <PageContent className="mt-6 font-normal flex">
        <Trans>
          <Bold>For Popular Places Pass</Bold>
        </Trans>
      </PageContent>
      <PageContent className="mt-2 font-normal flex">
        <span className="ml-2 mr-4">•</span>
        <Trans>
          You need to apply for a Popular Places Pass only if you are visiting the
          following areas on Sundays or public holidays:
        </Trans>
      </PageContent>
      <PageContent className="ml-5 mt-2 font-normal flex-column">
        {popularPlacesLocations.map((place) => {
          return (
            <p key={place}>
              <span className="ml-4 mr-4">•</span>
              {_i18n._(place)}
            </p>
          );
        })}
      </PageContent>
      <PageContent className="mt-2 font-normal flex">
        <span className="ml-2 mr-4">•</span>
        {popularPlacesAllowanceMessage}
      </PageContent>
      <PageContent className="mt-2 font-normal flex">
        <span className="ml-2 mr-4">•</span>
        <div>
          <Trans>
            You <Bold>DO NOT</Bold> need to apply for any pass to visit a recreation
            centre (RC) or community areas outside of the areas listed above. You can
            visit these locations anytime. You also <Bold>DO NOT</Bold> need to apply for
            a Popular Places Pass to visit the areas listed above on non-public holiday
            weekdays or Saturdays.
          </Trans>
        </div>
      </PageContent>
    </>
  );
};

const InstructionTexts: FC<Props> = ({
  hasNonRC,
  hasRC,
  quota = {
    daysInAdvance: 7,
    rc: {
      cycleType: CycleType.WEEK,
      cycleCount: 1,
      cycleQuota: 1,
      quotas: [],
    },
    "non-rc": {
      cycleType: CycleType.WEEK,
      cycleCount: 1,
      cycleQuota: 1,
      quotas: [],
    },
    "popular-place": {
      cycleType: CycleType.DAY,
      cycleCount: 1,
      cycleQuota: 1,
      quotas: [],
    },
  },
}) => {
  const hasPOPS = getGlobal("REACT_APP_POPULAR_PLACES") === "true";
  const hasNonRCEnv = getGlobal("REACT_APP_HAS_NON_RC") === "true";
  return (
    <>
      <MainHeaderTitle />
      {hasPOPS && popularPlacesMessage(quota)}
      {!hasPOPS && hasRC && vaccinatedRCMessage(quota)}
      {!hasPOPS && hasNonRCEnv && hasNonRC && nonRCMessage(quota)}
      <Divider className="mt-6 mb-8" />
    </>
  );
};

export default InstructionTexts;
