import { t } from "@lingui/macro";
import _i18n from "i18n";

const dictionary: { [key: string]: string } = {
  Nearby: t`Nearby`,
  "Areas within 15 mins walk of my dormitory": t`Areas within 15 mins walk of my dormitory`,
  "North/Central": t`North/Central`,
  East: t`East`,
  West: t`West`,
  Others: t`Others`,
  "Other areas": t`Other areas`,
  "Other areas (Please specify)": t`Other areas (Please specify)`,
  "Enter a valid area name": t`Enter a valid area name`,
  "Nearest RC": t`Nearest RC`,
  "Nearest RC (ART)": t`Nearest RC (ART)`,
  "Nearest RC (RRT-PCR)": t`Nearest RC (RRT-PCR)`,
  "Nearest Recreation Centre": t`Nearest RC`,
  "Scheduled RRT": t`Scheduled RRT`,
  "Ad-hoc ART": t`Ad-hoc ART`,
  "RC Scheduled RRT": t`RC Scheduled RRT`,
  "RC Ad-hoc ART": t`RC Ad-hoc ART`,
  "PoPs": t`Popular Places`,
  "Chinatown": t`Chinatown`,
  "Geylang Serai": t`Geylang Serai`,
  "Jurong East" : t`Jurong East`,
  "Little India" : t`Little India`,
};

const getTranslatedText = (text: string): string => {
  if (dictionary[text]) return _i18n._(dictionary[text]);

  // Translate the text in parenthesis:
  //  eg. Penjuru RC (Scheduled RRT) -> Penjuru RC (预定轮流定期检测（RRT）)
  const re = /.+\((.+)\)$/;
  const hint = text.match(re)?.[1];

  return hint && dictionary[hint]
    ? text.replace(hint, _i18n._(dictionary[hint]))
    : text;
};

export default getTranslatedText;
