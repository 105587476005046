import React, { FC, useEffect } from "react";
import { useHistory, Prompt } from "react-router-dom";
import { connect } from "react-redux";
import { Trans, t } from "@lingui/macro";

import _i18n from "i18n";
import { RootState } from "reducers";
import { Routes } from "common/routes";
import Permit from "pages/PermitPage/Permit";
import { resetApplicationDetails } from "pages/PersonalInfoPage/applicationDetailSlice";
import getGlobal from "utils/getGlobal";
import os, { OS } from "utils/os";
import {
  Button,
  ButtonAppearance,
  Divider,
  Message,
  PageTitle,
  PageContent,
  GridContainer,
} from "shared-components";
import { PermitDetails } from "./permitSlice";
import sgworkpass from "./imgs/sgworkpass.png";
import styles from "./style.module.scss";

interface PermitPageProps {
  permit: PermitDetails;
  resetApplicationDetails: () => void;
}

export const PermitPage: FC<PermitPageProps> = ({ permit, resetApplicationDetails }) => {
  const history = useHistory();
  // redirect if user accesses permit page directly
  if (!permit.id) {
    history.push(Routes.FAQ_PAGE);
    return <></>;
  }

  const hasPOPS = getGlobal("REACT_APP_POPULAR_PLACES") === "true";

  useEffect(() => {
    const wogaaTrackingId = getGlobal("REACT_APP_WOGAA_TRACKING_ID");
    if (wogaaTrackingId) {
      window.wogaaCustom?.completeTransactionalService(wogaaTrackingId);
    }

    return () => {
      // reset application details after applied pass
      if (!window.location.pathname.includes(Routes.PERMIT_PAGE)) {
        resetApplicationDetails();
      }
    };
  }, []); // eslint-disable-line

  useEffect(() => {
    if (!permit.id) {
      history.push(Routes.FAQ_PAGE);
    }
    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      event.preventDefault();
      event.returnValue = "hasChanges";
    };
    window.addEventListener("beforeunload", handleBeforeUnload);
    return () => window.removeEventListener("beforeunload", handleBeforeUnload);
  }, [history, permit, resetApplicationDetails]);

  const nonRCBullet = (
    <Trans>
      Your <span className="font-semibold">Exit Pass</span> in the SGWorkPass app (if
      applicable)
    </Trans>
  );
  const popularPlaceBullet = (
    <Trans>
      Your <span className="font-semibold">Popular Places Pass</span> in the SGWorkPass
      app (if applicable)
    </Trans>
  );

  const nonRCReminders = (
    <>
      <p className="flex font-normal mt-2">
        <span className="mx-2">•</span>
        <span>
          <Trans>
            You must ensure that you return to your dormitory within the time slot.
          </Trans>
        </span>
      </p>
      <p className="flex font-normal mt-2">
        <span className="mx-2">•</span>
        <span>
          <Trans>You are allowed to visit only the location stated above.</Trans>
        </span>
      </p>
    </>
  );

  return (
    <GridContainer className="pb-8">
      <Prompt
        message={({ pathname }) =>
          pathname === Routes.PERMIT_PAGE
            ? true
            : _i18n._(
                t`You will not be able to return to this page later. You can view your Pass details in the SGWorkPass app.`
              )
        }
      />

      <PageTitle>
        <Trans>Your application is successful</Trans>
      </PageTitle>

      <PageContent className="mt-5">
        <Trans>What to bring when you go out:</Trans>
      </PageContent>
      <PageContent className="mt-2 font-normal flex">
        <span className="ml-2 mr-4">•</span>
        <div>
          <Trans>
            Your <span className="font-semibold">Work Pass card</span>
          </Trans>
        </div>
      </PageContent>
      <PageContent className="mt-2 font-normal flex">
        <span className="ml-2 mr-4">•</span>
        <div>
          <Trans>
            Your <span className="font-semibold">mobile phone</span>
          </Trans>
        </div>
      </PageContent>
      <PageContent className="mt-2 font-normal flex">
        <span className="ml-2 mr-4">•</span>
        <div>{hasPOPS ? popularPlaceBullet : nonRCBullet}</div>
      </PageContent>
      <PageContent className="mt-2 font-normal flex">
        <span className="ml-2 mr-4">•</span>
        <div>
          <Trans>
            Your <span className="font-semibold">TraceTogether token</span>
          </Trans>
        </div>
      </PageContent>
      <Divider className="my-8" />

      <Permit permit={permit} id="canvas-permit" hasPOPS={hasPOPS} />

      <button
        id="open-sgworkpass-view-exit-pass-btn"
        className={`${styles.sgwpButton} flex bg-web-black text-white items-center justify-between font-semibold focus:outline-none`}
        onClick={() => {
          const link =
            os.getOS() === OS.IOS || os.getOS() === OS.MAC
              ? "https://apps.apple.com/sg/app/sgworkpass/id1274061632"
              : "https://play.google.com/store/apps/details?id=sg.gov.mom.sgworkpass&hl=en";
          window.open(link, "_blank");
        }}
      >
        <i className="far fa-mobile ml-4 mr-4 text-2xl" />
        <div className="text-left">
          <Trans>
            <div>Open SGWorkPass to view your pass</div>
          </Trans>
        </div>
        <img className={`${styles.sgwpImage} ml-4`} alt="SGworkpass" src={sgworkpass} />
      </button>
      <Message className="mt-4 mb-0">
        <h1 className="text-lg font-semibold">
          <Trans>Reminders</Trans>
        </h1>
        <p className="flex font-normal mt-2">
          <span className="mx-2">•</span>
          <span>
            <Trans>
              If you are unwell, you should not go out even if you have applied for a
              pass.
            </Trans>
          </span>
        </p>
        {!hasPOPS && nonRCReminders}
        <p className="flex font-normal mt-2">
          <span className="mx-2">•</span>
          <span>
            <Trans>
              Any breach of the pass guidelines may result in the suspension of future
              application of pass or your work pass being revoked.
            </Trans>
          </span>
        </p>
      </Message>
      <Button
        id="apply-another-exit-pass-btn"
        className="mt-6 mb-6 sm:px-12 px-6 rounded-full"
        appearance={ButtonAppearance.TRANS_RECT_BLUE}
        onClick={() => history.push(Routes.INFO_PAGE)}
      >
        <i className="fas fa-redo text-base ml-2 pr-2"></i>
        <Trans>Apply for another pass</Trans>
      </Button>
    </GridContainer>
  );
};

const mapDispatchToProps = {
  resetApplicationDetails,
};

const mapStateToProps = (state: RootState) => ({
  permit: state.permitSlice.permit,
});

export default connect(mapStateToProps, mapDispatchToProps)(PermitPage);
