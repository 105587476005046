import { format, isSameDay, parse } from "date-fns";

const DATE_FORMAT = "dd MMM yyyy";
const TIME_FORMAT = "hh:mmaa";
const DATETIME_FORMAT = "h:mmaa, dd MMM yyyy";

export const getDateTimeRange = (startTime?: Date, endTime?: Date): string => {
  if (!startTime) return "";
  let formattedTimeRange = format(startTime, DATETIME_FORMAT);

  if (endTime) {
    if (isSameDay(startTime, endTime)) {
      formattedTimeRange = `${format(startTime, TIME_FORMAT)} - ${format(
        endTime,
        DATETIME_FORMAT
      )}`;
    } else {
      formattedTimeRange = `${format(startTime, DATETIME_FORMAT)} - ${format(
        endTime,
        DATETIME_FORMAT
      )}`;
    }
  }

  return formattedTimeRange;
};

export const getCurrentDate = () => {
  return format(new Date(), DATE_FORMAT);
};

export const changeDateFormat = (
  date: string,
  oldFormat: string,
  newFormat: string
) => {
  return format(parse(date, oldFormat, new Date()), newFormat);
};
