import React, { ReactNode } from "react";
import styles from "./style.module.scss";

export enum MessageWidth {
  md = "420px",
  lg = "728px",
}

export enum MessageIndicator {
  Green = "green",
  Yellow = "yellow",
  Orange = "orange",
  Red = "red",
}

interface IndicatorProps {
  indicator?: MessageIndicator;
  type?: string;
}

interface MessageProps {
  className?: string;
  children: ReactNode;
  indicator?: MessageIndicator;
  width?: MessageWidth;
  type?: "warning" | "alert" | "error";
  title?: string | ReactNode;
  name?: string;
}

const IndicatorIcon = ({ indicator, type }: IndicatorProps): JSX.Element | null => {
  if (!indicator) {
    return null;
  }

  let textClass = styles.red;

  switch (indicator) {
    case MessageIndicator.Yellow:
      textClass = styles.yellow;
      break;
    case MessageIndicator.Orange:
      textClass = styles.orange;
      break;
    default:
      break;
  }

  let iconClass = "fas fa-exclamation-square";
  switch (type) {
    case "warning":
      iconClass = "fas fa-minus-circle";
      break;
    case "alert":
      iconClass = "fas fa-info-circle";
      break;
    case "error":
      iconClass = "fas fa-exclamation-square";
      break;
    default:
      break;
  }

  return <i className={`${iconClass} ${textClass} ${styles.icon}`} />;
};

const Message = (props: MessageProps): JSX.Element => (
  <div
    className={`text-base text-white p-4 rounded-lg flex ${props.className || ""} ${
      styles.body
    }`}
    id={props.name}
  >
    <div className={"flex"}>
      <IndicatorIcon indicator={props.indicator} type={props.type} />
      {props && props.title && <div>{props.title}</div>}
    </div>
    <div className="w-full">{props.children}</div>
  </div>
);

export default Message;
