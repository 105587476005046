import "react-app-polyfill/ie9";
import "react-app-polyfill/stable";
import React from "react";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import ReactDOM from "react-dom";
import store from "store";
import { I18nProvider } from "@lingui/react";

import AppRouter from "./routes/AppRouter";
import * as serviceWorker from "./serviceWorker";
import _i18n from "./i18n";
import "./styles/fonts.css";
import "./styles/fontawesome.css";
import "./styles/tailwind.css";
import "./index.css";

ReactDOM.render(
  <Provider store={store}>
    {/* @ts-ignore */}
    <I18nProvider i18n={_i18n}>
      <BrowserRouter>
        <AppRouter />
      </BrowserRouter>
    </I18nProvider>
  </Provider>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
