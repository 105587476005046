import _i18n from "i18n";

const localeCodeMap: { [key: string]: string } = {
  en: "english",
  ta: "tamil",
  ms: "malay",
  zh: "chinese",
  bn: "bengali",
};

export type LocaleTextLanguage = { [key: string]: string } & {
  english: string;
  chinese?: string;
  malay?: string;
  tamil?: string;
  bengali?: string;
};

// Get locale text based on current selected language,
// fallback to English text if not available
export const getLocaleText = (localeText: LocaleTextLanguage): string => {
  const locale = _i18n._locale;
  return localeText[localeCodeMap[locale]] || localeText[localeCodeMap.en];
};

export const hideStr = (value: string): string => {
  const stringToHide = value.substr(1, 4);
  return value.replace(stringToHide, "****");
};
