import React, { ReactNode } from "react";
import SelectSearch, {
  SelectedOptionValue,
  SelectSearchOption,
} from "react-select-search";
import "./styles.scss";

interface FieldSelectProps {
  id: string;
  options: SelectSearchOption[];
  onChange: (value: string) => void;
  className?: string;
  placeholder?: string;
  selected?: string | null;
  icon?: ReactNode;
}

const getSelectedValue = (
  options: SelectSearchOption[],
  selected?: string | null
): SelectSearchOption | null => {
  if (!selected) return null;

  if (options.length < 1) return null;

  if (Object.keys(options[0]).includes("options")) {
    const allOptions: SelectSearchOption[] = options.reduce(
      (acc, cur) => {
        return [...acc, ...cur.options];
      },
      [] as SelectSearchOption[]
    );
    return allOptions.find(({ value }) => value === selected) || null;
  }

  return options.find(({ value }) => value === selected) || null;
};

const FieldSelect = (props: FieldSelectProps): JSX.Element => {
  const onChange = (selectedValue: SelectedOptionValue | SelectedOptionValue[]): void => {
    props.onChange(selectedValue as string);
  };

  return (
    <div className={`${props.className || ""}`}>
      {props.icon && <span className="absolute z-10 pt-2 pl-4">{props.icon}</span>}
      <SelectSearch
        id={props.id}
        options={props.options}
        placeholder={props.placeholder}
        onChange={onChange}
        autoComplete={"off"}
      />
    </div>
  );
};

export default FieldSelect;
