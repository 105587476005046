import React, { FC, useEffect, useRef } from "react";
import ReactDatePicker from "react-datepicker";
import styles from "./style.module.scss";

interface Props {
  id: string;
  selected?: Date | null;
  onChange: (date: Date | null, event: React.SyntheticEvent<any> | undefined) => void;
  className?: string;
  disabled?: boolean;
  minDate?: Date;
  autoComplete?: boolean;
}

const DatePicker: FC<Props> = ({
  id,
  selected,
  onChange,
  disabled,
  minDate,
  className = "",
  autoComplete,
}) => {
  const inputRef = useRef(null);

  useEffect(() => {
    const inputEle = document.getElementById(id);

    // make datepicker remain as active field when pressing Esc/Enter
    const handleKeyPress = (e: any) => {
      if (e.key === "Enter" || e.key === "Escape") {
        setTimeout(() => inputEle?.focus());
      }
    };

    inputEle?.addEventListener("keydown", handleKeyPress);
    return () => {
      inputEle?.removeEventListener("keydown", handleKeyPress);
    };
  }, []);

  return (
    <div className={`${styles.body} w-full`}>
      <ReactDatePicker
        id={id}
        ref={inputRef}
        className={`pl-10 pr-4 py-3 flex items-center w-full z-0 rounded shadow-input ${className}`}
        selected={selected}
        showYearDropdown
        showMonthDropdown
        onChange={onChange}
        disabled={disabled}
        minDate={minDate}
        maxDate={new Date()}
        dateFormat="dd-MM-yyyy"
        dropdownMode="select"
        placeholderText="e.g. 31-01-1980"
        autoComplete={autoComplete ? "on" : "off"}
      />
      <i className={`${styles.icon} far fa-calendar-alt text-web-blue`} />
    </div>
  );
};

export default DatePicker;
