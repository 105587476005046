import React, { FC } from "react";
import styles from "./style.module.scss";

const SkipLinks: FC = () => {
  return (
    <ul className={styles.skipLink}>
      <li>
        <a href="#language-selector">Skip to language selection</a>
      </li>
      <li>
        <a href="#main">Skip to main content</a>
      </li>
    </ul>
  );
};

export default SkipLinks;
