import classnames from "classnames/bind";
import React, {
  FC,
  ReactNode,
  MouseEventHandler,
  ButtonHTMLAttributes,
  DetailedHTMLProps,
} from "react";

import styles from "./style.module.scss";

const cx = classnames.bind(styles);

export enum ButtonAppearance {
  TRANS_RECT_BLUE = "trans rect blue",
  SOLID_RECT_BLUE = "solid rect blue",
  SOLID_ROUND_BLUELIGHT = "solid round bluelight",
  SOLID_ROUND_GRAYDARK = "solid round graydark",
}

interface Props {
  id?: string;
  type?: DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>;
  appearance: ButtonAppearance;
  children: ReactNode;
  onClick?: MouseEventHandler;
  className?: string;
  disabled?: boolean;
}

const Button: FC<Props> = ({
  id,
  appearance,
  children,
  onClick,
  className,
  disabled,
}) => {
  const buttonClass = cx({
    button: true,
    "bg-web-blue text-white text-base font-semibold px-4 py-2 rounded outline-none focus:outline-none flex items-center":
      appearance === ButtonAppearance.SOLID_RECT_BLUE,
    "bg-web-bluelight text-white font-semibold px-5 py-3 rounded-full outline-none border border-solid border-white focus:outline-none flex items-center":
      appearance === ButtonAppearance.SOLID_ROUND_BLUELIGHT,
    "bg-web-graydark text-white text-base font-semibold px-5 py-3 rounded-full outline-none focus:outline-none flex items-center":
      appearance === ButtonAppearance.SOLID_ROUND_GRAYDARK,
    [`bg-transparent border-white ${
      disabled ? "opacity-50 cursor-not-allowed" : "opacity-100"
    } border text-white text-base font-semibold px-4 py-2 rounded outline-none focus:outline-none flex items-center`]:
      appearance === ButtonAppearance.TRANS_RECT_BLUE,
  });

  return (
    <button
      id={id}
      className={classnames(buttonClass, className)}
      onClick={onClick}
      disabled={disabled}
      type="submit"
    >
      {children}
    </button>
  );
};

export default Button;
