import React, { FC, ChangeEventHandler, FocusEventHandler, ReactNode } from "react";
import styles from "./style.module.scss";

interface Props {
  id: string;
  name: string;
  value: any;
  icon?: ReactNode;
  onChange: ChangeEventHandler;
  onBlur?: FocusEventHandler;
  placeholder?: string;
  disabled?: boolean;
  maxLength?: number;
  autoComplete?: boolean;
  inputMode?:
    | "text"
    | "none"
    | "tel"
    | "url"
    | "email"
    | "numeric"
    | "decimal"
    | "search";
}

const FieldTextInput: FC<Props> = ({
  id,
  name,
  value,
  icon,
  onChange,
  onBlur,
  placeholder,
  disabled,
  maxLength,
  autoComplete,
  inputMode = "text",
}) => {
  const inputPadding = icon ? "pl-10 pr-3" : "px-3";
  return (
    <>
      {icon && <span className="absolute z-10">{icon}</span>}
      <input
        data-testid="text-input"
        type="text"
        id={id}
        name={name}
        value={value}
        placeholder={placeholder}
        disabled={disabled}
        onChange={onChange}
        onBlur={onBlur}
        className={`w-full py-2 appearance-none rounded bg-white leading-tight shadow-input ${inputPadding} ${styles.body}`}
        autoComplete={autoComplete ? "on" : "off"}
        maxLength={maxLength}
        inputMode={inputMode}
      />
    </>
  );
};

export default FieldTextInput;
