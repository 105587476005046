import React from "react";
import _i18n from "i18n";

import { Routes } from "common/routes";
import { Masthead, GridContainer, SkipLinks } from "shared-components";
import Hyperlink, { HyperlinkAppearance } from "shared-components/Hyperlink";
import logoMom from "./images/mom-logo.webp";

const PageHeader = () => {
  return (
    <>
      <SkipLinks />
      <Masthead link={Routes.GOV_SG} title="A Singapore Government Agency Website" />

      <nav className="bg-white">
        <GridContainer className="flex justify-between items-center bg-white py-4">
          <Hyperlink
            path={Routes.MOM_GOV_SG}
            isInternal={false}
            appearance={HyperlinkAppearance.DEFAULT}
          >
            <img src={logoMom} alt="Ministry of Manpower logo" className="w-24 sm:w-32" />
          </Hyperlink>
        </GridContainer>
      </nav>
    </>
  );
};

export default PageHeader;
