import _i18n from "i18n";
import { t } from "@lingui/macro";
import { isBefore, isAfter, subDays, parseISO } from "date-fns";
import { getLocaleText } from "utils/strings";
import { getDateTimeRange } from "utils/dates";

export type BannerContentType = null | {
  shouldDisplay: boolean;
  messages: string;
  timeString?: string;
};

type getDefaultMessagesType = (key: string) => string;

//This method is used to provide data for unit tests
const getDefaultMessages: getDefaultMessagesType = (key) => {
  const messages: { [key: string]: string } = {
    Maintenance: _i18n._(
      t`The system will be under scheduled maintenance from:`
    ),
  };

  return messages[key];
};

export const getBannerContent = async (): Promise<BannerContentType> => {
  const parsedContent = await (
    await fetch(`${window.location.origin}/banner/data.json`)
  ).json();

  if (!parsedContent) return null;

  const {
    startTime: startTimeString,
    endTime: endTimeString,
    displayStart: displayStartString,
    displayEnd: displayEndString,
    defaultMessage,
    ...messages
  } = parsedContent;

  if (!startTimeString && !displayStartString) return null;

  const startTime = startTimeString && parseISO(startTimeString);
  const endTime = endTimeString && parseISO(endTimeString);
  const displayTimeStart = displayStartString
    ? parseISO(displayStartString)
    : subDays(startTime, 7);
  const displayTimeEnd = displayEndString
    ? parseISO(displayEndString)
    : endTime;

  const now = Date.now();

  return {
    messages: getDefaultMessages(defaultMessage) || getLocaleText(messages),
    timeString: getDateTimeRange(startTime, endTime),
    shouldDisplay: displayTimeEnd
      ? isAfter(now, displayTimeStart) && isBefore(now, displayTimeEnd)
      : isAfter(now, displayTimeStart),
  };
};
