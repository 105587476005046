import React, { FC } from "react";
import { Route, RouteProps } from "react-router-dom";
import FAQButton from "shared-components/FAQButton";

const PageRoute: FC<RouteProps> = (props) => {
  return (
    <>
      <FAQButton />
      <main id="main" className="flex flex-grow mx-auto max-w-3xl">
        <Route exact {...props} />
      </main>
    </>
  );
};

export default PageRoute;
