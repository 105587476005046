import { useState, useEffect } from "react";

export const useStateWithSessionStorage = (sessionStorageKey: string) => {
  const [value, setValue] = useState<string>(
    sessionStorage.getItem(sessionStorageKey) || ""
  );

  useEffect(() => {
    sessionStorage.setItem(sessionStorageKey, value);
  }, [value, sessionStorageKey]);

  return [value, setValue] as const;
};
