import React, { FC } from "react";
import ReactDatePicker from "react-datepicker";

import "react-datepicker/dist/react-datepicker.css";
import styles from "./style.module.scss";

interface Props {
  selected: Date;
  onChange: (date: Date | null, event: React.SyntheticEvent<any> | undefined) => void;
  timeIntervals?: number;
  className?: string;
  disabled?: boolean;
  dateFormat?: string;
  minTime?: Date;
}

const TimePicker: FC<Props> = ({
  selected,
  onChange,
  disabled,
  className,
  minTime,
  timeIntervals = 15,
  dateFormat = "h:mm aa",
}) => {
  return (
    <div className={`${styles.body}`}>
      <ReactDatePicker
        className={`pl-10 pr-4 py-2 w-full rounded shadow-input ${className}`}
        selected={selected}
        onChange={onChange}
        disabled={disabled}
        minTime={minTime}
        showTimeSelect
        showTimeSelectOnly
        timeIntervals={timeIntervals}
        timeCaption="Time"
        dateFormat={dateFormat}
      />
      <i className={`${styles.icon} far fa-clock text-web-blue`}></i>
    </div>
  );
};

export default TimePicker;
