import React, { FC } from "react";
import { PageTitle } from "shared-components";
import getGlobal from "utils/getGlobal";
import { Trans, t } from "@lingui/macro";

const MainHeaderTitle: FC = () => {
  const hasPOPS = getGlobal("REACT_APP_POPULAR_PLACES") === "true";
  return (
    <PageTitle>
      {hasPOPS ? (
        <Trans>Apply for Popular Places Pass</Trans>
      ) : (
        <Trans>Apply for Dormitory Exit Pass</Trans>
      )}
    </PageTitle>
  );
};

export default MainHeaderTitle;
