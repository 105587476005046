import { createSlice } from "@reduxjs/toolkit";

export interface PermitDetails {
  id: string;
  identityNumber: string;
  validDate: string;
  validTimeFrom: string;
  validTimeTo: string;
}

const permitSlice = createSlice({
  name: "permit",
  initialState: {
    permit: {} as PermitDetails,
  },
  reducers: {
    setPermit(state, action) {
      state.permit = action.payload;
    },
  },
});

export const { setPermit } = permitSlice.actions;

export default permitSlice.reducer;
