import React, { FC, useState, useEffect } from "react";
import { connect } from "react-redux";
import { Trans } from "@lingui/macro";
import styles from "./style.module.scss";
import { RootState } from "reducers";
import getTranslatedText from "utils/dictionary";
import getGlobal from "utils/getGlobal";

import {
  Location,
  MapResults,
  LocationType,
} from "pages/PersonalInfoPage/applicationDetailSlice";

interface LocationVisitProps {
  location?: Location;
  isCard?: boolean;
  isPermitPage?: boolean;
  mapResults?: MapResults[];
}

const ONE_MAP_URL = getGlobal("REACT_APP_ONE_MAP_URL") || "https://www.onemap.sg/main/v2";

const checkIsART = (name: string) => new RegExp(/.+ART\)$/).test(name);

const LocationVisit: FC<LocationVisitProps> = ({
  location,
  isCard = true,
  isPermitPage = false,
  mapResults = [],
}) => {
  const { name, type } = location || {};
  const [url, setUrl] = useState<string>("");

  useEffect(() => {
    if (mapResults && mapResults.length) {
      const lat = mapResults[0].LATITUDE ? mapResults[0].LATITUDE : "";
      const lng = mapResults[0].LONGITUDE ? mapResults[0].LONGITUDE : "";
      if (lat && lng) {
        setUrl(`${ONE_MAP_URL}/?lat=${lat}&lng=${lng}`);
      }
    }
  }, [mapResults]);

  return (
    <div
      className={[
        styles.paper,
        !isCard ? styles.noCard : "",
        isPermitPage ? styles.permitPage : "",
      ].join(" ")}
    >
      <div className={styles.content}>
        <div className="sm:flex sm:flex-col">
          <section className={styles.contentGroup}>
            <h2 className={styles.contentTitle}>
              <Trans>Location to visit</Trans>
            </h2>
            {type === LocationType.NON_RECREATION_CENTRE && <Trans>Non-RC area</Trans>}
            {type === LocationType.POPULAR_PLACE && <Trans>Popular Places area</Trans>}
            {name ? (
              <div className={styles.contentBody}>
                {getTranslatedText(name)}{" "}
                {url && (
                  <a
                    href={url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="whitespace-no-wrap"
                  >
                    <span className="bg-web-bluelight">
                      <i className="far fa-external-link-square-alt" />
                      <Trans>Map</Trans>
                    </span>
                  </a>
                )}
                {checkIsART(name) && (
                  <div className="mt-2">
                    <Trans>
                      You will need to undergo an Antigen Rapid Test (ART) at the RC.
                    </Trans>
                  </div>
                )}
              </div>
            ) : (
              <div>
                <Trans>Nearest RC</Trans>
              </div>
            )}
          </section>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: RootState) => ({
  location: state.applicationDetailSlice.applicationDetails.location,
  mapResults: state.applicationDetailSlice.mapResults,
});

export default connect(mapStateToProps, null)(LocationVisit);
