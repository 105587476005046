import { createSlice } from "@reduxjs/toolkit";
import { ViolationType } from "pages/constants";

export interface ApplicationDetails {
  identityNumber: string;
  dateOfBirth?: Date | null;
  date?: string | null;
  timeRange?: string | null;
  location?: Location | null;
  locationType?: string | null;
  isNonRCAreaSelected?: boolean;
}

export enum LocationType {
  RECREATION_CENTRE = "RECREATION_CENTRE",
  NON_RECREATION_CENTRE = "NON_RECREATION_CENTRE",
  POPULAR_PLACE = "POPULAR_PLACE",
}

export type Location = {
  id: string;
  name: string;
  postalCode?: string;
  availableDateTimes: AvailableDateTimes;
  type: LocationType;
  category?: "Others" | "Nearby" | string | null;
};

export type AppliedPermit = {
  date: string;
  timeSlot: string;
  location: string;
};

export interface QuotaBalance {
  balance: number;
  startDate: string;
  endDate: string;
}

export enum CycleType {
  DAY = "DAY",
  WEEK = "WEEK",
  MONTH = "MONTH",
}

export interface QuotaInfo {
  cycleQuota: number;
  cycleCount: number;
  cycleType: CycleType;
  quotas: QuotaBalance[];
}

export type Quota = {
  daysInAdvance?: number;
  rc?: QuotaInfo;
  "non-rc"?: QuotaInfo;
  "popular-place"?: QuotaInfo;
};

export interface MapResults {
  SEARCHVAL: string;
  BLK_NO: string;
  ROAD_NAME: string;
  BUILDING: string;
  ADDRESS: string;
  POSTAL: string;
  LATITUDE: string;
  LONGITUDE: string;
}

export enum TimeSlotStatus {
  AVAILABLE = "AVAILABLE",
  FULL = "FULL",
  INELIGIBLE = "INELIGIBLE",
  APPLIED = "APPLIED",
  PARTIALLY_OVERLAPPED = "PARTIALLY_OVERLAPPED",
  FULLY_OVERLAPPED = "FULLY_OVERLAPPED",
  UNALLOCATED = "UNALLOCATED",
}

export enum VaccinationStatus {
  NOT_VACCINATED = "NOT_VACCINATED",
  VACCINATED = "VACCINATED",
  VACCINE_EXEMPTED = "VACCINE_EXEMPTED",
}

export interface TimeSlot {
  startTime: string;
  endTime: string;
  status: TimeSlotStatus;
}

export interface AvailableDateTimes {
  [key: string]: TimeSlot[];
}

export interface Blacklist {
  violationType: ViolationType;
  startDate?: string;
  endDate?: string;
}

export type InvalidationPeriod = {
  startDate?: string | null;
  endDate?: string | null;
};

const applicationDetailSlice = createSlice({
  name: "personalInfo",
  initialState: {
    applicationDetails: {
      identityNumber: "",
      dateOfBirth: null,
      date: null,
      timeRange: null,
      location: null,
      locationType: null,
      isNonRCAreaSelected: false,
    },
    status: "",
    appliedPermits: [],
    quota: {
      daysInAdvance: 0,
      rc: {
        cycleCount: 0,
        cycleType: CycleType.WEEK,
        cycleQuota: 0,
        quotas: [],
      },
      "non-rc": {
        cycleCount: 0,
        cycleType: CycleType.WEEK,
        cycleQuota: 0,
        quotas: [],
      },
      "popular-place": {
        cycleCount: 0,
        cycleType: CycleType.DAY,
        cycleQuota: 0,
        quotas: [],
      },
    },
    locations: [],
    mapResults: [],
    blacklists: [],
    invalidationPeriod: {},
    vaccinationStatus: VaccinationStatus.NOT_VACCINATED,
  },
  reducers: {
    resetApplicationDetails(state) {
      state.applicationDetails = {
        identityNumber: "",
        dateOfBirth: null,
        date: null,
        timeRange: null,
        location: null,
        locationType: null,
        isNonRCAreaSelected: false,
      };
    },
    resetApplicationDetailsPartial(state) {
      state.applicationDetails = {
        ...state.applicationDetails,
        date: null,
        timeRange: null,
        location: null,
        locationType: null,
        isNonRCAreaSelected: false,
      };
    },
    setApplicationDetails(state, action) {
      state.applicationDetails = {
        ...state.applicationDetails,
        ...action.payload,
      };
    },
    setAppliedPermits(state, action) {
      state.appliedPermits = action.payload;
    },
    setBlacklists(state, action) {
      state.blacklists = action.payload;
    },
    setQuota(state, action) {
      state.quota = action.payload;
    },
    setLocations(state, action) {
      state.locations = action.payload;
    },
    setMapResults(state, action) {
      state.mapResults = action.payload;
    },
    setInvalidationPeriod(state, action) {
      state.invalidationPeriod = action.payload;
    },
    setVaccinated(state, action) {
      state.vaccinationStatus = action.payload;
    },
  },
});

export const {
  setApplicationDetails,
  resetApplicationDetails,
  resetApplicationDetailsPartial,
  setAppliedPermits,
  setBlacklists,
  setQuota,
  setLocations,
  setMapResults,
  setInvalidationPeriod,
  setVaccinated,
} = applicationDetailSlice.actions;

export default applicationDetailSlice.reducer;
