import { combineReducers } from "redux";
import permitSlice from "pages/PermitPage/permitSlice";
import applicationDetailSlice from "pages/PersonalInfoPage/applicationDetailSlice";

const rootReducer = combineReducers({
  permitSlice,
  applicationDetailSlice,
});

export type RootState = ReturnType<typeof rootReducer>;
export default rootReducer;
