import React, { FC, useState } from "react";

import { Routes } from "common/routes";
import GridContainer from "shared-components/GridContainer";
import Hyperlink, { HyperlinkAppearance } from "shared-components/Hyperlink";
import { ReactComponent as LionheadMast } from "./images/lionhead-mast.svg";
import { ReactComponent as UpArrowIcon } from "./images/up-arrow.svg";
import { ReactComponent as DownArrowIcon } from "./images/down-arrow.svg";
import { ReactComponent as BuildingIcon } from "./images/building.svg";
import { ReactComponent as LockIcon } from "./images/lock.svg";
import "./styles.scss";

interface Props {
  link: string;
  title: string;
  className?: string;
}

const Masthead: FC<Props> = ({ link, title, className }) => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <section className={`${className ? className : ""} masthead-bg`}>
      <GridContainer>
        <div className="flex items-center py-1 text-sm">
          <a
            data-testid="mastheadLink"
            href={link}
            rel="noopener noreferrer"
            target="_blank"
            className="flex font-lato"
          >
            <LionheadMast />
            <span className="ml-1 font-lato">{title}</span>
          </a>

          <div
            data-testid="howToIdentifyLink"
            className="flex font-lato"
            onClick={() => setIsOpen(!isOpen)}
          >
            <a className="ml-1 font-lato underline identify-link">How to identify</a>
            {isOpen === true ? <UpArrowIcon /> : <DownArrowIcon />}
          </div>
        </div>

        {isOpen && (
          <div
            className="py-4 text-sm flex flex-col sm:flex-row"
            data-testid="mastheadAdditionalInfo"
          >
            <div className="trusted-sites">
              <div>
                <BuildingIcon />
              </div>
              <div className="trusted-sites__content">
                <p className="font-lato font-semibold">
                  Official website links end with .gov.sg
                </p>
                <p className="font-lato">
                  Government agencies communicate via .gov.sg websites (e.g.
                  go.gov.sg/open).&nbsp;
                  <Hyperlink
                    path={Routes.TRUSTED_WEBSITES}
                    isInternal={false}
                    appearance={HyperlinkAppearance.BLUE}
                  >
                    Trusted websites
                  </Hyperlink>
                </p>
              </div>
            </div>
            <div className="secure-sites">
              <div>
                <LockIcon />
              </div>
              <div>
                <p className="font-lato font-semibold">Secure websites use HTTPS</p>
                <p className="font-lato">
                  Look for a <b>lock</b> (<span>) </span>
                  or https:// as an added precaution. Share sensitive information only on
                  official, secure websites.
                </p>
              </div>
            </div>
          </div>
        )}
      </GridContainer>
    </section>
  );
};

export default Masthead;
