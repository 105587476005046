import React, { FC, useEffect, useState } from "react";

import { Routes } from "common/routes";
import GridContainer from "shared-components/GridContainer";
import Hyperlink, { HyperlinkAppearance } from "shared-components/Hyperlink";
import { Trans } from "@lingui/macro";
import _i18n from "i18n";
import styles from "./style.module.scss";

const WOGAA_WRAPPER_TAG = "wog-sentiments";
const WOGAA_CONTENT_ID = "wog--sentiments-content";

interface Props {
  lastUpdatedDate: string;
}

const PageFooter: FC<Props> = ({ lastUpdatedDate }) => {
  const [hasWidget, setHasWidget] = useState(false);

  useEffect(() => {
    const checkWidgetPresent = setInterval(() => {
      const wogaaWidget = document.getElementsByTagName(WOGAA_WRAPPER_TAG)?.[0];
      const childCount = wogaaWidget?.shadowRoot?.querySelector(
        `#${WOGAA_CONTENT_ID}`
      )?.childElementCount;
      setHasWidget(!!childCount);
    }, 1000);

    return () => clearInterval(checkWidgetPresent);
  }, []);

  return (
    <footer data-testid="footer" className={`bg-black py-8 ${hasWidget && "pb-12"}`}>
      <GridContainer>
        {/*
          Temporary remove as site is set to unavailable (keep warm state)

          <div className={styles.footerLinks}>
            <Hyperlink
              path={Routes.REPORT_VULNERABILITY}
              isInternal={false}
              appearance={HyperlinkAppearance.DEFAULT}
            >
              <Trans>Report Vulnerability</Trans>
            </Hyperlink>
            <Hyperlink
              path={Routes.PRIVACY_STATEMENT_PAGE}
              isInternal={true}
              appearance={HyperlinkAppearance.DEFAULT}
              openInNewTab={false}
            >
              <Trans>Privacy</Trans>
            </Hyperlink>
            <Hyperlink
              path={Routes.TERMS_OF_USE_PAGE}
              isInternal={true}
              appearance={HyperlinkAppearance.DEFAULT}
              openInNewTab={false}
            >
              <Trans>Terms of use</Trans>
            </Hyperlink>
          </div>
        */}

        <div className={styles.footerCopyrights}>
          <p>© 2022 Government of Singapore</p>
          <p>
            <Trans>Last updated</Trans> {lastUpdatedDate}
          </p>
        </div>
      </GridContainer>
    </footer>
  );
};

export default PageFooter;
