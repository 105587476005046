import classnames from "classnames";
import React, { FC, ReactNode } from "react";

interface Props {
  children: ReactNode;
  className?: string;
  id?: string;
}

const GridContainer: FC<Props> = ({ children, className, id }) => {
  return (
    <div id={id} className={classnames(`mx-auto max-w-2xl px-4 md:px-0`, className)}>
      {children}
    </div>
  );
};

export default GridContainer;
