import React, { useState } from "react";
import AsyncSelect from "react-select/async";
import axios, { AxiosResponse } from "axios";
import debounce from "lodash.debounce";
import styles from "./style.module.scss";

interface FieldLocationSearchProps {
  onChange: (value: string) => void;
  placeholder?: string;
  postalCode?: string;
}

interface OneMapItem {
  ADDRESS: string;
  POSTAL: string;
}

interface OneMapAPI {
  results: OneMapItem[];
}

const updateList = debounce((searchVal: string, callback: any) => {
  if (searchVal.length < 3) {
    callback([]);
    return;
  }

  axios
    .request({
      method: "get",
      url: "https://developers.onemap.sg/commonapi/search",
      params: { searchVal, returnGeom: "Y", getAddrDetails: "Y", pageNum: 1 },
    })
    .then((response: AxiosResponse<OneMapAPI>) => {
      const results = response?.data?.results
        .filter((i: OneMapItem) => i.POSTAL !== "NIL")
        .map((i: OneMapItem) => ({ label: i.ADDRESS, value: i.POSTAL }));
      callback(results || []);
    })
    .catch(() => callback([]));
}, 500);

const FieldLocationSearch = (props: FieldLocationSearchProps): JSX.Element => {
  const [searchTerm, setSearchTerm] = useState("");
  const onChange = (selected: any): void => {
    props.onChange(selected?.value || "");
  };

  const getNoResultsMessage = (): string => {
    if (searchTerm?.length > 0 && searchTerm?.length <= 3) {
      return "Please enter a longer search term.";
    }

    if (searchTerm?.length > 3) {
      return "Unable to get any results. Try another search term.";
    }

    return "Start by entering a search term";
  };

  return (
    <div className={`${styles.body}`}>
      <AsyncSelect
        isClearable={true}
        loadOptions={updateList}
        placeholder={props.placeholder}
        noOptionsMessage={getNoResultsMessage}
        onInputChange={setSearchTerm}
        onChange={onChange}
        className="map-search"
        classNamePrefix="map-search"
        cacheOptions={true}
      />
      <i className={`${styles.icon} far fa-map-marker-alt text-web-blue`}></i>
    </div>
  );
};

export default FieldLocationSearch;
